<template>
  <div class="flagship">
    <div class="flagship-container">
      <div ref="image" class="flagship-image">
        <CustomImage :image="flagship.data.image" :sizes="[369, 1390]" />
      </div>
      <div v-if="props.content === false" class="content">
        <h3 class="subtitle">{{ flagship.data.subtitle }}</h3>
        <PrismicRichText class="title" :field="flagship.data.title" />
        <Link
          :label="flagship.data.link_label"
          :link="flagship.data.calendly_link.url"
          :light="true"
          :external="true"
        />
      </div>
    </div>
    <div class="infos-container">
      <div class="infos infos-left">
        <Link link="/contact-informations#flagship" label="Explore More" />
        <p v-show="!isMobile" class="location-label">Location</p>
      </div>
      <div class="infos infos-right">
        <PrismicRichText class="location" :field="flagship.data.location" />
        <Link
          v-if="!isMobile"
          link="https://maps.app.goo.gl/dCbWT1Lc6ijS9N2d9"
          label="Directions"
          :external="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";

import Link from "@/components/Link";
import { useMobile } from "@/composables/useMobile";
const { isMobile } = useMobile();

const { client } = usePrismic();
const { data: flagship } = await useAsyncData("flagship", () =>
  client.getSingle("flagship_link")
);

const props = defineProps({
  content: Boolean,
});

let ctx;

const image = ref(null);

onMounted(() => {
  initAnimations();
});

const initAnimations = () => {
  ctx = gsap.context(() => {
    gsap.to(image.value, {
      yPercent: 20,
      ease: "none",
      scrollTrigger: {
        trigger: image.value,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  });
};

onBeforeUnmount(() => {
  ctx?.kill(false);
});
</script>

<style lang="scss" scoped>
.flagship {
  padding: 1.3rem 1.3rem $padding-mobile-big;
  background-color: $white;
  @include desktop {
    padding: $padding-desktop $padding-desktop $padding-desktop-big;
  }
}

.flagship-container {
  height: 50.1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  overflow: hidden;
  @include desktop {
    height: 81rem;
    max-height: 95vh;
  }
}

.flagship-image {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2);
  bottom: 10%;
  will-change: transform;
}

.content {
  z-index: 1;
  text-align: center;
  color: $white;
}

.subtitle {
  @include caption-small;
  margin-bottom: 1rem;
  @include desktop {
    @include caption-medium;
    margin-bottom: 1.5rem;
  }
}

.title {
  font-family: $beaufort-regular;
  font-size: 4.2rem;
  margin-bottom: 2rem;

  :deep(strong) {
    font-family: $suisse-regular;
    font-size: 3.8rem;
    letter-spacing: -0.114rem;
    font-weight: 400;
    @include desktop {
      font-size: 7.1rem;
      letter-spacing: -0.308rem;
    }
  }
  :deep(h2) {
    line-height: 3.1rem;
    letter-spacing: -0.168rem;
    @include desktop {
      font-size: 7.7rem;
      letter-spacing: -0.213rem;
      line-height: 6rem;
    }
  }

  @include desktop {
    margin-bottom: 5rem;
  }
}

.infos-container {
  display: flex;
  width: 100%;
}

.infos {
  width: 50%;
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}

.infos-left {
  padding-right: 2rem;
}

.infos-right {
  padding-left: 0.6rem;
  @include desktop {
    padding-left: 1.1rem;
  }
}

.location,
.location-label {
  @include body-small;
}

.location-label {
  @include desktop {
    padding-right: 3.6rem;
  }
}
</style>
